import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";

const Index = () => {
  useEffect(() => {
    document.body.classList.remove("dark");
  }, []);
  return (
    <Redirect to="/login" />
  );
};

export default Index;